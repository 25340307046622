<template>
  <WidthWrapper
    class="mb-40 lg:mb-50"
    data-aller-id="content-large-teaser"
    :style="brand?.colors.primary.background && `--color-primary: ${brand?.colors.primary.background}`"
  >
    <article class="-mx-16 lg:mx-0">
      <NuxtLink
        :to="componentContent.cta?.href"
        class="group block overflow-hidden text-white lg:rounded-3xl"
        @click="trackClick()"
      >
        <div class="relative">
          <picture>
            <source media="(min-width:1024px)" :srcset="`${componentContent.media?.imageUrl}?width=1024&height=379&format=webp 1024w, ${componentContent.media?.imageUrl}?width=1280&height=473&format=webp 1280w, ${componentContent.media?.imageUrl}?width=2048&height=758&format=webp 2048w`" sizes="(min-width: 1280px) 1280px, 100vw">
            <img :src="`${componentContent.media?.imageUrl}?width=320&height=325&format=webp`" :srcset="`${componentContent.media?.imageUrl}?width=320&height=325&format=webp 320w, ${componentContent.media?.imageUrl}?width=640&height=650&format=webp 640w, ${componentContent.media?.imageUrl}?width=960&height=975&format=webp 960w`" sizes="100vw" :alt="componentContent.media?.alt || componentContent.media?.imageUrl" width="320" height="325" :fetchpriority="index === 0 ? 'high' : 'low'" :loading="index === 0 ? 'eager' : 'lazy'">
          </picture>
          <div class="absolute inset-0 flex items-end px-16 py-25">
            <div class="md:w-50p lg:px-48">
              <div v-if="brand?.logo" class="mb-30 flex h-[36px] items-center justify-start">
                <img v-if="brand.logo?.imageUrl" :src="brand.logo?.imageUrl" :alt="`${brand.title} logo`" class="h-full w-auto max-w-[80%] invert" width="77" height="50" :loading="index === 0 ? 'eager' : 'lazy'">
              </div>
              <div v-if="product" class="inline-flex items-center font-semibold">
                <div>{{ product.title }}</div>
                <BubbleLabel class="ml-10">
                  Fra {{ findLowestProductVariantPrice(product.variants) }} kr.
                </BubbleLabel>
              </div>
            </div>
          </div>
        </div>
        <div class="relative px-16 py-20 lg:flex lg:flex-row lg:items-end lg:justify-between lg:px-48 lg:pb-48 lg:pt-36">
          <picture class="absolute inset-0 -z-10">
            <source media="(min-width:1024px)" :srcset="`${componentContent.media?.imageUrl}?width=1024&height=379&format=webp 1024w, ${componentContent.media?.imageUrl}?width=1280&height=473&format=webp 1280w, ${componentContent.media?.imageUrl}?width=2048&height=758&format=webp 2048w`" sizes="(min-width: 1280px) 1280px, 100vw">
            <img :src="`${componentContent.media?.imageUrl}?width=320&height=325&format=webp`" :srcset="`${componentContent.media?.imageUrl}?width=320&height=325&format=webp 320w, ${componentContent.media?.imageUrl}?width=640&height=650&format=webp 640w, ${componentContent.media?.imageUrl}?width=960&height=975&format=webp 960w`" sizes="100vw" :alt="componentContent.media?.alt || componentContent.media?.imageUrl" width="320" height="325" class="h-full w-full" :loading="index === 0 ? 'eager' : 'lazy'">
          </picture>
          <div class="absolute inset-0 -z-10 bg-black/30 backdrop-blur-3xl" />
          <div class="lg:w-6/12">
            <h2 v-if="componentContent.title" class="mb-8 text-20 font-semibold leading-120p lg:text-24">
              {{ componentContent.title }}
            </h2>
            <ContentWysiwyg :component-content="{ type: 'text', text:componentContent.description }" />
          </div>
          <Button
            v-if="componentContent.cta?.href"
            class="mb-16"
          >
            {{ componentContent.cta?.text }}
            <LazySharedIcon
              name="chevron-right"
              size="size-24"
            />
          </Button>
        </div>
      </NuxtLink>
    </article>
  </WidthWrapper>
</template>

<script setup lang="ts">
import { ProductWithVariantsTransformer, type ContentPageBrand, type LargeTeaserBlock } from '@aller/nuxt-allerservice-sdk/transformers';

const props = defineProps({
  componentContent: {
    type: Object as PropType<LargeTeaserBlock>,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
});

const product = props.componentContent.relatedProductGuid ? await useCommerce().products().id(props.componentContent.relatedProductGuid).then((data) => ProductWithVariantsTransformer(data.value)) : null;

const brands = useState<ContentPageBrand[]>('brands');
const brand = ref<ContentPageBrand | null>();
// As the brands state will only be present on client we move the data setting to mounted.
onMounted(() => {
  brand.value = product ? brands?.value?.filter((brand) => brand.guid === product.brand?.guid)[0] : null;
});

const trackClick = () => {
  useGTM().pushElement('click_teaser_big', {
    section_id: `content-large-teaser-${props.componentContent.guid}`,
    section_text: undefined,
    element_id: product?.advantagePromotionCode || 'content-large-teaser',
    element_text: product ? 'Køb abonnement' : props.componentContent.cta?.text || '',
    element_link: product?.url || props.componentContent.cta?.href,
  });
};

</script>
